import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4rem;
  background-color: #f9f9f9;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 4rem;
  color: #555;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  gap: 16px;
`;

const StyledNavLink = styled(Link)`
  text-decoration: none;
`;

const Card = styled.div`
  width: 300px;
  height: 480px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 1rem;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:hover .hover-overlay {
    opacity: 1;
  }
`;

const HoverOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 64px;
  background-color: rgba(70, 51, 30, 0.80); 
  color: #fff;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  padding: 4px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  z-index: 1;
`;

const CardTitle = styled.h2`
  font-size: 1.1rem;
  color: #333;
  text-align: center;
  margin-bottom: 0.5rem;
`;

const Divider = styled.hr`
  width: 80%;
  border: 0;
  height: 1px;
  background-color: #ddd;
  margin: 1rem 0;
`;

const CardText = styled.p`
  font-size: 0.9rem;
  color: #666;
  text-align: left;
  line-height: 1.6;
  flex-grow: 1;
`;

const StyledExternalLink = styled.a`
  text-decoration: none;
`;

const TopBusinessList = () => {
  return (
    <Wrapper>
      <Title>Service</Title>
      <CardContainer>
        <StyledNavLink to="/design">
          <Card>
            <HoverOverlay className="hover-overlay">AINNA designの概要サイトを開きます</HoverOverlay>
            <CardTitle>デザイン＆プロモーション事業</CardTitle>
            <Divider />
            <CardText><strong>デジタルマーケ戦略、クリエイティブ制作、プロモーション企画まで一貫対応</strong><br/>
              WebサイトやSNS運用を含むデジタルマーケティング支援、オリジナルアイテムのデザイン・製造、プロモーション施策の企画・実施含めサポートします。
              <br/>
              <br/>提供サービス
              <br/>・デジタルマーケティング戦略の立案・運用支援
              <br/>・Webサイト・SNS運用の設計・実施
              <br/>・オリジナルアイテムのデザイン・製造
              <br/>・プロモーション施策の企画・ディレクション
            </CardText>
          </Card>
        </StyledNavLink>
        <StyledExternalLink
  href="https://conotyapp.com/"
  target="_blank"
  rel="noopener noreferrer"
>
  <Card>
    <HoverOverlay className="hover-overlay">
      当社アプリ「CONOTY」のサイトを開きます
    </HoverOverlay>
    <CardTitle>アプリ開発事業</CardTitle>
    <Divider />
    <CardText>
      <strong>自社アプリの運営から受託開発まで、幅広く対応</strong><br />
      iPhone、Android用アプリケーションの開発を行っております。
      <br />自社のアプリを開発、運営するとともに、お客様のアプリ開発も承ります。
      <br />
      <br />提供サービス
      <br />・お知らせ管理アプリ「CONOTY」の開発、運営
      <br />・その他自社アプリの企画
      <br />・顧客専用アプリの開発、運用
      <br />・既存アプリプロモーション機能開発
    </CardText>
  </Card>
</StyledExternalLink>


  <Card>
    <HoverOverlay className="hover-overlay">
      現在リニューアル準備中
    </HoverOverlay>
    <CardTitle>コスメ事業</CardTitle>
    <Divider />
    <CardText>
      <strong>化粧品の輸入販売から輸入代行までサポート</strong><br />
      AINNAは化粧品の輸入事業からスタートしました。
      <br />現在は自社での輸入販売だけではなく、輸入代行も行っております。
      <br />
      <br />提供サービス
      <br />・ニュージーランドからplus dollopの輸入及び販売
      <br />・化粧品の輸入代行
      <br />・化粧品の企画、OEM製造
      <br />・化粧品関連製品の企画、OEM製造
    </CardText>
  </Card>

      </CardContainer>
    </Wrapper>
  );
};

export default TopBusinessList;
