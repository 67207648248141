import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import aboutUsTop from '../../images/about-us-top.png'; // 背景画像
import ainnaLogo from '../../images/ainna-logo-wh-ja.png'; // ロゴ画像
import openingVideo from '../../images/ainna_openning.mp4';

const fadeIn = keyframes`
  from {
    opacity: 0;
    background-color: white; 
  }
  to {
    opacity: 1;
    background-color: transparent;
  }
`;

const HeroContainer = styled.div`
  width: 100%;
  height: auto;
  max-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: white;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const BackgroundContainer = styled.div`
  width: 100%;
  display: auto;
  justify-content: center;
  align-items: center;
  position: relative;
  animation: ${fadeIn} 1.5s ease-in-out;
`;

const HeroImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const LogoImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  max-width: 300px;
`;

const TopHero = () => {
  const [showVideo, setShowVideo] = useState(true);

  const handleVideoEnd = () => {
    setTimeout(() => setShowVideo(false), 100);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleVideoEnd();
    }, 43400);
    return () => clearTimeout(timer);
  }, []);

  return (
    <HeroContainer>
      {showVideo ? (
        <Video
          src={openingVideo}
          autoPlay
          muted
          onEnded={handleVideoEnd}
        />
      ) : (
        <BackgroundContainer>
          <HeroImage src={aboutUsTop} alt="About Us Top" />
          <LogoImage src={ainnaLogo} alt="Ainna Logo" />
        </BackgroundContainer>
      )}
    </HeroContainer>
  );
};

export default TopHero;
