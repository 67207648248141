import React from 'react';
import DesignWorksKitahiroshima from "./design_works_kitahiroshima";
import DesignWorksCrossHotel from './design_works_crosshotel';

const styles = {
    container: {
        width: '100%',
        maxWidth: '1280px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '20px',
        margin: '0 auto',
        padding: '8px',
    },
    title: {
        textAlign: 'center',
        fontSize: '32px',
        fontWeight: 'bold',
        margin: '80px 0px 40px 0px',
        color: '#46331e',
    },
    subtitle: {
        textAlign: 'center',
        fontSize: '16px',
        margin: '0px 0px 40px 0px',
        color: '#46331e',
    },

};

const DesignWorks = () => {
    return (
        <div>
            <h2 style={styles.title}>WORKS</h2>
            <h2 style={styles.subtitle}>AINNA designで実現したお客様の取り組みの一部をご紹介いたします。</h2>
            <div style={styles.container}>
                <DesignWorksKitahiroshima />
                <DesignWorksCrossHotel />
            </div>
        </div>
    );
};

export default DesignWorks;
