import React, { useEffect } from 'react';
import Footer from '../components/footer/footer';
import Header from '../components/header/header';
import DesignHero from '../components/design/design_hero';
import DesignMessage from '../components/design/design_message';
import DesignWorks from '../components/design/design_works';
import DesignMenu from '../components/design/design_menu';
import DesignContactForm from '../components/design/design_contuct';

const AppDevPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div style={{ paddingTop: "60px", backgroundColor: '#FAFAFA' }}>
        <DesignHero />
        <DesignMessage />
        <DesignWorks />
        <DesignMenu />
        <DesignContactForm />
      </div>
      <Footer />
    </div>
  );
};

export default AppDevPage;
