import React, { useEffect } from 'react';
import Footer from '../components/footer/footer';
import Header from '../components/header/header';
import ContactForm from '../components/contact/contact';

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div style={{ paddingTop: "60px", backgroundColor: '#FAFAFA' }}>
        <ContactForm />
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
