import React from 'react';
import styled from 'styled-components';
import designLogo from '../../images/design_logo_tr.png'; // ロゴ画像をインポート

const DesignMenuContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 400px;
  position: relative;
  margin: 0 auto;
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin: 40px 0px 20px 0px;
  color: #46331e;
`;

const SubTitle = styled.div`
  text-align: center;
  font-size: 16px;
  color: #46331e;
  margin: 20px 40px 40px 40px;
`;

const LogoImage = styled.img`
  display: block;
  margin: 0px auto;
  padding: 40px 0;
  width: 240px; /* サイズ調整はここで */
  height: auto;
`;

const MenuSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ align }) => (align === 'left' ? 'flex-start' : 'flex-start')};
  padding: 20px;
  position: relative;
  margin-left: 20px;
`;

const MenuDivider = styled.div`
  width: 4px;
  background-color: #46331e;
  height: 100%;
  position: absolute;
  left: 0;
`;

const RoundedBox = styled.div`
  position: relative;
  top: 0;
  margin: 10px 0;
  border-radius: 10px;
  padding: 8px 12px;
  background-color: #46331e;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fadeIn 0.5s ease-out 0.5s forwards;
`;

const GlobalStyle = styled.div`
  @keyframes stretchRight {
    from {
      width: 0;
      transform: translateX(0);
    }
    to {
      width: 50px;
      transform: translateX(50px);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const DesignMenu = () => {
  return (
    <>
      <GlobalStyle />
      <Title>Service Menu</Title>
      <SubTitle>
        プロモーションやマーケティングに関すること色々ご相談ください<br/>
        お客様のニーズに合わせてご提案いたします
      </SubTitle>
      <DesignMenuContainer>
        <MenuDivider />
        <MenuSide>
          <RoundedBox>マーケティングコンサルティング</RoundedBox>
          <RoundedBox>マーケティング施策の企画およびディレクション</RoundedBox>
          <RoundedBox>HP/LP制作および運用</RoundedBox>
          <RoundedBox>SNSセッティング</RoundedBox>
          <RoundedBox>SNS運用</RoundedBox>
          <RoundedBox>SNS広告企画および運営</RoundedBox>
          <RoundedBox>商品パッケージデザインおよび手配</RoundedBox>
          <RoundedBox>フライヤーデザインおよび手配</RoundedBox>
          <RoundedBox>販促品の企画/デザインおよび手配</RoundedBox>
          <RoundedBox>展示会ブースデザインおよびトータルディレクション</RoundedBox>
        </MenuSide>
      </DesignMenuContainer>
      <LogoImage src={designLogo} alt="Design Logo" />

    </>
  );
};

export default DesignMenu;
