import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-family: 'Playfair Display', serif;
  color: #4a4a4a;
  background-color: #fafafa;
  padding: 30px;
  line-height: 1.8;
  border-radius: 10px;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 30px;
`;

const Copy = styled.h1`
  font-size: 2em;
  font-weight: bold;
  color: #2c2c2c;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-bottom: 2px solid #2c2c2c;
  display: inline-block;
  padding-bottom: 5px;

  @media (max-width: 768px) {
    font-size: 1.2em;
}
`;

const Main = styled.main`
  max-width: 750px;
  margin: 0 auto;
  text-align: justify;
`;

const Intro = styled.p`
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #555;
  line-height: 1.8;

  @media (max-width: 768px) {
    font-size: 1em;
}
`;

const DesignMessage = () => {
  return (
    <Container>
      <Header>
        <Copy>「あなたの想いを、愛を込めて形に。」</Copy>
      </Header>
      <Main>
        <Intro>
          たくさんの案件をこなすのではなく、ひとつひとつのお客様にじっくりと向き合う。
          <br />私たちは、その姿勢を大切にしています。
          高価格で豪華すぎるものでもなく、テンプレートでありきたりなものでもない、
          お客様の“ちょうどいい”を見つけ出し、心を込めて実現します。
        </Intro>
        <Intro>
          企業や事業のスタート時“まずは最低限”でやってこられた方々が、
          次のステージとして“ワンランク上”の独自性を手に入れるお手伝い。
          それが、私たちの得意とすることです。
        </Intro>
        <Intro>
          『愛を込めて、あなたに』
          <br />
          <br />
          をモットーに、次のステージを目指す皆様の想いを一緒に描きます。
        </Intro>
      </Main>
    </Container>
  );
};

export default DesignMessage;
