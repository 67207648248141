import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom"; // React RouterのLinkをインポート
import ainnaLogo from "../../images/ainna_logo_tr.png";

const HeaderContainer = styled.header`
  width: 100vw; /* 画面全体の幅に固定 */
  height: 60px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: space-between; /* 左右に要素を配置 */
  align-items: center;
  padding: 0 40px; /* 上下は0、左右に40pxの余白 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 10;
  box-sizing: border-box; /* パディングを含めた全体幅を計算 */
`;

const LogoLink = styled(Link)`
  display: flex; /* ロゴ全体をクリック可能にするための設定 */
  align-items: center;
`;

const LogoImage = styled.img`
  height: 24px;
  object-fit: contain;
`;

const NavLinksContainer = styled.div`
  display: flex; /* 横並び */
  gap: 20px; /* 各リンク間のスペース */
`;

const DesignLink = styled(Link)`
  color: #000; /* テキストカラー */
  text-decoration: none; /* 下線を消す */
  font-size: 16px; /* フォントサイズ */
  font-weight: bold; /* 太字 */
  &:hover {
    text-decoration: underline; /* ホバー時に下線を表示 */
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <LogoLink to="/"> {/* ロゴをクリックするとトップページに戻る */}
        <LogoImage src={ainnaLogo} alt="Ainna Logo" />
      </LogoLink>
      <NavLinksContainer>
        <DesignLink to="/design">Design</DesignLink> {/* Designへのリンク */}
        <DesignLink to="/about">About us</DesignLink> {/* About usへのリンク */}
        <DesignLink to="/contact">Contact</DesignLink> {/* Contactへのリンク */}
      </NavLinksContainer>
    </HeaderContainer>
  );
};

export default Header;
