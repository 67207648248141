import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  padding: 1rem;
  background-color: #ffffff;
  color: #333;
`;

const MessageBox = styled.div`
  max-width: 800px;
  font-size: 1.125rem;
  line-height: 1.8;
  background: #fff;
  padding: 4rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 767px) {
    padding: 2.0rem;
  }
`;

const Title = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #555;
`;

const Subtitle = styled.h2`
  font-size: 1.3rem;
  margin-bottom: 2rem;
  color: #555;
`;

const Content = styled.p`
  margin-top: 1rem;
  font-size: 1.1rem;
  color: #666;

  @media (max-width: 767px) {
    font-size: 0.85rem;
}
`;


const Footer = styled.p`
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #666;
`;

const TopMessage = () => {
  return (
    <>
      <Wrapper>
        <MessageBox>
          <Title>Message</Title>
          <Subtitle>愛を込めて、あなたに</Subtitle>
          <Content>
          <p>
            「明日を楽しみに眠れる毎日を」お届けすること、
            <br />それが私たちAINNAの大切にしている想いです。
            <br />
            <br />私たちは、サービスや製品を手に取るすべての人々のことを思い描き、
            <br />常に「どうすれば喜んで使っていただけるか」を第一に考えています。
            <br />
            <br />人々の生き方に貢献できる本当に価値あるサービスや製品をお届けしたい。
            <br />
            <br />それがAINNAの使命です。
            <br />
            <br />私たちの提供するサービスや製品が皆さまの
            <br />「パートナー」となれるよう日々努力を重ねています。
            <br />
            <br />AINNAは常にお客様の幸せを考え、進化を続けてまいります。
            <br />どうぞご期待ください。
          </p>
          </Content>
          <Footer>株式会社AINNA<br />代表取締役 石井 健吾</Footer>
        </MessageBox>
      </Wrapper>
    </>
  );
};

export default TopMessage;
