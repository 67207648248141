import React from 'react';
import TopHero from '../components/top/top_hero';
import TopMessage from '../components/top/top_message';
import TopBusinessList from '../components/top/top_business';
import TopNews from '../components/top/top_news';
import Footer from '../components/footer/footer';
import Header from '../components/header/header';

const TopPage = () => {
  return (
    <div>
      <Header />
      <div style={{ paddingTop: "60px" }}>
      <TopHero />
      <TopMessage />
      <TopBusinessList />
      <TopNews />
      </div>
      <Footer />
    </div>
  );
};

export default TopPage;