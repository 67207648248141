import React from "react";
import styled from "styled-components";

const Title = styled.div`
  text-align: center; /* 左右中央揃え */
  font-size: 32px; /* 文字サイズを大きく */
  font-weight: bold; /* 文字を太く */
  margin: 80px 0px 40px 0px; /* タイトル下に余白を追加 */
  color: #46331e; /* タイトルの文字色を設定 */
`;

const SubTitle = styled.div`
  text-align: center; /* 左右中央揃え */
  font-size: 16px; /* 文字サイズを大きく */
  color: #46331e; /* タイトルの文字色を設定 */
  margin: 40px; /* タイトル下に余白を追加 */
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const Form = styled.form`
  width: 100%;
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
`;

const FormGroup = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  min-height: 100px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #46331e;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    font-weight: bold;
    background-color: #400000;
  }
`;

const DesignContactForm = () => {
  return (
    <div>
      <Title>問い合わせ</Title>
      <SubTitle>
        こちらはプロモーションやマーケティング関連のお問い合わせです<br />
        お気軽にお問い合わせください
      </SubTitle>
      <FormContainer>
        <Form>
          <FormGroup>
            <Label htmlFor="companyName">社名もしくは店舗名</Label>
            <Input type="text" id="companyName" name="companyName" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="department">所属部門</Label>
            <Input type="text" id="department" name="department" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="position">役職</Label>
            <Input type="text" id="position" name="position" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="name">氏名</Label>
            <Input type="text" id="name" name="name" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="email">メールアドレス</Label>
            <Input type="email" id="email" name="email" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="phone">電話番号</Label>
            <Input type="tel" id="phone" name="phone" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="inquiry">問い合わせ内容</Label>
            <TextArea id="inquiry" name="inquiry"></TextArea>
          </FormGroup>
          <Button type="submit">送信</Button>
        </Form>
      </FormContainer>
    </div>
  );
};

export default DesignContactForm;
