import React, { useState, useRef } from "react";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

// 仮のreCAPTCHAサイトキー
const RECAPTCHA_SITE_KEY = "6LcedAIrAAAAADyTVWEinQs2muhkvnQWu-Q-XIng";

// 各種スタイル定義
const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin: 80px 0px 40px 0px;
  color: #46331e;
`;

const SubTitle = styled.div`
  text-align: center;
  font-size: 16px;
  color: #46331e;
  margin: 40px;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const Form = styled.form`
  width: 100%;
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
`;

const FormGroup = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  min-height: 100px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #46331e;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  &:hover {
    font-weight: bold;
    background-color: #400000;
  }

  &:disabled {
    background-color: #999;
    cursor: not-allowed;
  }
`;

const Spinner = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
  margin: 0 auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ContactForm = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    department: "",
    position: "",
    name: "",
    email: "",
    phone: "",
    inquiry: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const recaptchaRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // フロントバリデーション
    if (!formData.name || !formData.email || !formData.inquiry) {
      alert("必須項目をすべて入力してください。");
      return;
    }

    if (!validateEmail(formData.email)) {
      alert("メールアドレスの形式が正しくありません。");
      return;
    }

    if (!recaptchaToken) {
      alert("reCAPTCHAを確認してください。");
      return;
    }

    setIsSubmitting(true);

    try {
      const res = await fetch("https://us-central1-ainna-hp.cloudfunctions.net/sendContactEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, recaptchaToken }),
      });

      if (res.ok) {
        alert("送信が完了しました！");
        setFormData({
          companyName: "",
          department: "",
          position: "",
          name: "",
          email: "",
          phone: "",
          inquiry: "",
        });
        recaptchaRef.current.reset();
        setRecaptchaToken("");
      } else {
        alert("送信に失敗しました。しばらくしてから再度お試しください。");
      }
    } catch (error) {
      console.error("送信エラー:", error);
      alert("通信エラーが発生しました。");
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      <Title>問い合わせ</Title>
      <SubTitle>
        当社サービスに関わること全ての問い合わせページです。
        <br />
        お気軽にお問い合わせください
      </SubTitle>
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          {[
            { name: "companyName", label: "社名もしくは店舗名" },
            { name: "department", label: "所属部門" },
            { name: "position", label: "役職" },
            { name: "name", label: "氏名 *" },
            { name: "email", label: "メールアドレス *" },
            { name: "phone", label: "電話番号" },
          ].map(({ name, label }) => (
            <FormGroup key={name}>
              <Label htmlFor={name}>{label}</Label>
              <Input
                type={name === "email" ? "email" : name === "phone" ? "tel" : "text"}
                id={name}
                name={name}
                value={formData[name]}
                onChange={handleChange}
                required={["name", "email"].includes(name)}
              />
            </FormGroup>
          ))}
          <FormGroup>
            <Label htmlFor="inquiry">問い合わせ内容 *</Label>
            <TextArea
              id="inquiry"
              name="inquiry"
              value={formData.inquiry}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={(token) => setRecaptchaToken(token)}
              ref={recaptchaRef}
            />
          </FormGroup>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? <Spinner /> : "送信"}
          </Button>
        </Form>
      </FormContainer>
    </div>
  );
};

export default ContactForm;
