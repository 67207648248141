import React from 'react';
import styled from 'styled-components';
import image from '../../images/design_works_kitahiroshima.png';

const Background = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
`;


const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Square = styled.div`
  flex-basis: 200px;
  height: 200px;
  background-color: #46331e;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;

  @media (max-width: 768px) {
    flex-basis: 120px;
    height: 120px;
    margin-right: 10px;
  }
`;


const TextSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MessageTitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 12px;
}
`;

const MessageBody = styled.p`
  font-size: 16px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 10px;
}

`;

const ImageGallery = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 12px;
}
`;

const Subtitle = styled.p`
  font-size: 16px;
  margin: 0;
  margin-top: 8px;

  @media (max-width: 768px) {
    font-size: 10px;
}
`;

const DesignWorksKitahiroshima = () => {
  return (
    <Background>
      <ContentRow>
        <Square>
          <Title>WORKS</Title>
          <Subtitle>北広島市様</Subtitle>
        </Square>
        <TextSection>
          <MessageTitle>
            北広島市の観光を盛り上げるプロモーションを行政と企画
          </MessageTitle>
          <MessageBody>
            周遊デジタルスタンプラリー<br />
            「集まれ北広島！集めろスタンプ！ 温泉サウナとサ飯旅」
          </MessageBody>
        </TextSection>
      </ContentRow>
      <ImageGallery>
        <StyledImage
          src={image}
          alt="Design Work 1"
        />
      </ImageGallery>
    </Background>
  );
};

export default DesignWorksKitahiroshima;
