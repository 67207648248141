import React from 'react';
import styled from 'styled-components';
import designTop from '../../images/design_top_image_wide.png';
import designLogo from '../../images/design_logo_tr.png';

const HeroContainer = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
`;

const HeroImage = styled.img`
  width: 100%;
  max-width: 1600px;
  object-fit: cover;
  margin: 0 auto;
  display: block; /* Remove any potential inline spacing */
`;

const LogoImage = styled.img`
  position: absolute;
  top: 80%;
  left: 20%;
  transform: translate(-50%, -50%);
  width: 24%;
  max-width: 400px;
`;

const DesignHero = () => {
  return (
    <HeroContainer>
      <HeroImage src={designTop} alt="Design Top" />
      <LogoImage src={designLogo} alt="Design Logo" />
    </HeroContainer>
  );
};

export default DesignHero;