import React from "react";
import styled from "styled-components";
import ainnaLogo from "../../images/ainna-logo-wh-tr.png";

const FooterContainer = styled.footer`
  width: 100%;
  height: 160px;
  background-color: #7d7d7d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  position: relative;
  z-index: 10;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const LogoImage = styled.img`
  height: 40px;
  object-fit: contain;
`;

const FooterText = styled.p`
  margin: 0;
  padding: 10px;
  font-size: 14px;
  color: #fff;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <LogoWrapper>
        <LogoImage src={ainnaLogo} alt="Ainna Logo" />
      </LogoWrapper>
      <FooterText>© AINNA Co., Ltd. 2019-2025. All rights reserved.</FooterText>
    </FooterContainer>
  );
};

export default Footer;
