import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import TopPage from './pages/page_top'; 
import DesignPage from './pages/page_design_top'; 
import AppDevPage from './pages/page_appdev_top';
import AboutUsPage from './pages/page_aboutus';
import ContactPage from './pages/page_contact';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TopPage />} />
        <Route path="/design" element={<DesignPage />} />
        <Route path="/app" element={<AppDevPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </Router>
  );
}

export default App;