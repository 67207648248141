import React, { useEffect } from 'react';
import Footer from '../components/footer/footer';
import Header from '../components/header/header';

import styled from "styled-components";

// 画像ファイルをインポート
import aboutUsTopImage from '../images/about-us-top.png';

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`;

const OverlayText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Times New Roman", Times, serif;
  font-size: 96px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.6);
  pointer-events: none;
`;

const TextSection = styled.div`
  background-color: #f7f7f7;
  color: #333;
  text-align: center;
  padding: 40px 20px;
  
  p {
    font-size: 14px;
    margin: 4px 0;
    line-height: 1.6;
  }
`;

const MessageSection = styled.div`
  background-color: #fff;
  color: #333;
  text-align: center;
  padding: 40px 20px;
  max-width: 400px;
  margin: 0 auto;

  h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  p {
    font-size: 14px;
    line-height: 1.8;
  }
`;

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <ImageContainer>
        <img src={aboutUsTopImage} alt="About Us Top" style={{ width: '100%' }} />
        <OverlayText>AINNA</OverlayText>
      </ImageContainer>

      <TextSection>
        <p>About us</p>
        <p>---</p>
        <br/>
        <p>株式会社AINNA</p>
        <p>代表者</p>
        <p>代表取締役 石井 健吾</p>
        <br/>
        <p>東京都港区赤坂1-1-17細川ビル5階</p>
        <br/>
        <p>設立: 2019年10月8日</p>
        <br/>
        <p>事業概要</p>
        <p>Webプロモーションコンサル・代行</p>
        <p>オリジナル製品のODM/OEM開発</p>
        <p>Webサイトやオリジナルアプリの開発・運営</p>
        <p>スマートフォンアプリ「CONOTY」の開発・運営</p>
        <p>化粧品・美容関連商品の輸入・製造および販売</p>
        <br/>
        <p>主要取引金融機関: 三菱UFJ銀行</p>
      </TextSection>

      <MessageSection>
        <p>Message</p>
        <p>---</p>
        <br/>
        <p>愛を込めて、あなたに</p>
        <p>AINNA</p>
        <br/>
        <p>「明日を楽しみに眠れる毎日を」お届けすること、それが私たちAINNAの大切にしている想いです。</p>
        <p>私たちは、サービスや製品を手に取るすべての人々のことを思い描き、常に「どうすれば喜んで使っていただけるか」を第一に考えています。</p>
        <p>使う人々の生き方に貢献できる本当に価値あるサービスや製品をお届けたい。それがAINNAの使命です。</p>
        <p>私たちの提供するサービスや製品がすべての皆さまの「パートナー」となれるよう日々努力を重ねています。</p>
        <p>AINNAは、常にお客様の幸せを最優先に、これからも進化を続けてまいります。どうぞご期待ください。</p>
        <br/>
        <p>株式会社AINNA<br/>代表取締役<br/>石井 健吾</p>
      </MessageSection>

      <Footer />
    </>
  );
};

export default AboutUsPage;
