import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4rem;
  background-color: #ffffff;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #555;
`;

const Card = styled.div`
  width: 100%;
  max-width: 600px;
  min-height: 100px;
  margin: 8px;
  background-color: #ffffff;
  box-sizing: border-box;
  border: 2px solid #f0f0f0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
`;

const Content = styled.div`
  font-size: 1rem;
  color: #333;
`;

const Date = styled.div`
  font-size: 0.8rem;
  color: #888;
`;

const TopNews = () => {
  return (
    <Wrapper>
      <Title>NEWS</Title>
      <Card>
        <Content>AINNAのコーポレートページがリニューアルされました!</Content>
        <Date>2025年03月25日</Date>
      </Card>
      <Card>
        <Content>
          お知らせ管理アプリ「CONOTY」で先行ユーザーを対象にAmazonギフト券最大5万円プレゼントキャンペーンを実施しています。
          <br />
          詳細は
          <a
            href="https://note.com/conoty_official/n/n25d685f05755"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
          をご覧ください。
        </Content>
        <Date>2025年2月3日</Date>
      </Card>
      <Card>
        <Content>
          お知らせ管理アプリ「CONOTY」を先行リリースしました!
          <br />
          詳細は
          <a
            href="https://note.com/conoty_official/n/nb5d2cb60e63c"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
          をご覧ください。
        </Content>
        <Date>2024年12月24日</Date>
      </Card>
      <Card>
        <Content>AINNAが創立5周年を迎えました!!
          <br/>ご愛顧いただいております、皆様のおかげです。
          <br/>これからもAINNAをどうぞよろしくお願いいたします。
        </Content>
        <Date>2024年10月8日</Date>
      </Card>
    </Wrapper>
  );
};

export default TopNews;
